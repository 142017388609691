import * as Sentry from '@sentry/vue'
import { useRuntimeConfig } from 'nuxt/app'

export default defineNuxtPlugin(nuxtapp => {
  const runtimeConfig = useRuntimeConfig() as { public: { sentry: { dsn: string, environment: string } } }
  const router = useRouter()

  Sentry.init({
    app: nuxtapp.vueApp,
    dsn: runtimeConfig.public.sentry?.dsn,
    environment: runtimeConfig.public.sentry?.environment,
    tracesSampleRate: runtimeConfig.public.sentry?.environment === 'production' ? 0.2 : 1.0,
    profilesSampleRate: runtimeConfig.public.sentry?.environment === 'production' ? 0.2 : 1.0,
    debug: false,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.vueIntegration({
        app: nuxtapp.vueApp,
        tracingOptions: {
          trackComponents: true,
        },
      }),
      Sentry.replayIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
  })
})
