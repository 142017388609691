<template>
  <div font-inter text-neutral-900 bg-background-primary>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
html,body, #__nuxt {
  height: 100%;
  font-family: 'Inter', 'sans-serif';
}
</style>
