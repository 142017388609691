import revive_payload_client_zLrkl0cddS from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VQZcppnbKR from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_92YiDlmwFw from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_IcTbK93vGN from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.5.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_TAHwWttp2C from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import _01_plugin_Vw8rFfKGJC from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.74.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_terser@5.39._nr5heb4b474hghvcgu6jqh5zdm/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import navigation_repaint_client_lKapzyS7Od from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_p0v7uTA438 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nPOl3WtWnM from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mpTpts533Z from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/taurex/.nuxt/components.plugin.mjs";
import prefetch_client_dgQFJ5OD3j from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_nc7ikefkbsf4l5jqs6lw2nx7em/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_zR4LHhXjUn from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.8.2_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_qrcode@1.5.4_ty_l2uows5g7bjyg6un32ojkdgudq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_3Agu7xq8OT from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.8.2_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_qrcode@1.5.4_ty_l2uows5g7bjyg6un32ojkdgudq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_fqOiYdvqrR from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.8.2_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_qrcode@1.5.4_ty_l2uows5g7bjyg6un32ojkdgudq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_TLBkqFjcvG from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_terser@5.39.0_tsx_fxo55fgefioxjhmsztn5d6b2oa/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_jEF6owsg7I from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/taurex/.nuxt/unocss.mjs";
import plugin_wYxi0zIhB6 from "/vercel/path0/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.5.3_magicast@0.3.5_webpack@5.97.1_esbuild@0.24.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import bottomSheet_client_C55D02kKcz from "/vercel/path0/layers/components/fubex-components/plugins/bottomSheet.client.ts";
import validation_H6BESNSSPe from "/vercel/path0/layers/components/fubex-components/plugins/validation.ts";
import vue_toastification_client_OdKXnwPIgP from "/vercel/path0/layers/components/fubex-components/plugins/vue-toastification.client.ts";
import auto_animate_XIZCskOXXg from "/vercel/path0/layers/base/plugins/auto-animate.ts";
import chart_client_bcfhrmN7hg from "/vercel/path0/layers/base/plugins/chart.client.ts";
import sentry_client_dyxoAk6TFV from "/vercel/path0/layers/base/plugins/sentry.client.ts";
import use_head_sEf1F6ycmZ from "/vercel/path0/layers/base/plugins/use-head.ts";
import _05_hooks_Thzywr3F48 from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.74.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_terser@5.39._nr5heb4b474hghvcgu6jqh5zdm/node_modules/nuxt-i18n-micro/dist/runtime/plugins/05.hooks.js";
import _03_define_hGSou2QCUF from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.74.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_terser@5.39._nr5heb4b474hghvcgu6jqh5zdm/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  revive_payload_client_zLrkl0cddS,
  unhead_VQZcppnbKR,
  router_92YiDlmwFw,
  supabase_client_IcTbK93vGN,
  _0_siteConfig_TAHwWttp2C,
  _01_plugin_Vw8rFfKGJC,
  navigation_repaint_client_lKapzyS7Od,
  check_outdated_build_client_p0v7uTA438,
  chunk_reload_client_nPOl3WtWnM,
  plugin_vue3_mpTpts533Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dgQFJ5OD3j,
  slideovers_zR4LHhXjUn,
  modals_3Agu7xq8OT,
  colors_fqOiYdvqrR,
  plugin_TLBkqFjcvG,
  plugin_client_jEF6owsg7I,
  unocss_MzCDxu9LMj,
  plugin_wYxi0zIhB6,
  bottomSheet_client_C55D02kKcz,
  validation_H6BESNSSPe,
  vue_toastification_client_OdKXnwPIgP,
  auto_animate_XIZCskOXXg,
  chart_client_bcfhrmN7hg,
  sentry_client_dyxoAk6TFV,
  use_head_sEf1F6ycmZ,
  _05_hooks_Thzywr3F48,
  _03_define_hGSou2QCUF
]